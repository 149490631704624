.gallery-container {
    display: block;
}
.flex-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5%;
    height: 49vh;
}

.flex-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 600px) {
    .picture-container {
        width: 98%;
        height: 98%;
    }

    .flex-picture-container {
        width: 98%;
        height: 98%;
    }
    img {
        max-height: unset;
        object-fit: contain;
    }
}

img {
    max-width: 98%;
    max-height: 49vh;
    object-fit: contain;
}