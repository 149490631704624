.annualIncome {
    display: flex;
    flex-direction: row;
    justify-content: center;   
}

.annualIncomeText {
}

.annualIncomeInput {
}
