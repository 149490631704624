.NavBar {
    text-align: center;
    font-family: monospace;
}

.Top {
    display: flex;
    align-items: baseline;
    font-size: 45px;
    color: white;
    text-decoration: none;

}

.Title {
    font-size: 24px;
    color: #4af626;;
}

.Name {
    text-decoration: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.NavButtonContainer {
    border-top: 1px solid #4af626;
    display: flex;
    justify-content: flex-end;
    /*border-bottom: 1px solid #4af626;*/
}

.Logo-button-1 {
    width: 36px;
    height: 36px;
    font-size: 20px;
    text-align: center;
    border: none;
    display: inline-block;
    background-color: black;
    cursor: pointer;
}

.Logo-button-2 {
    width: 36px;
    height: 36px;
    font-size: 20px;
    text-align: center;
    border: none;
    display: inline-block;
    background-color: black;
    cursor: pointer;
}

.fa-linkedin {
    color:lightgrey;
    -webkit-transition-duration: 0.6s; /* Safari */
    transition-duration: 0.6s;
}

.fa-github {
    color: lightgrey;
    -webkit-transition-duration: 0.6s; /* Safari */
    transition-duration: 0.6s;
}

.Logo-button-1:hover .fa-linkedin{
    color: #0077b2;
}

.Logo-button-2:hover .fa-github{
    background-color: white;
    color: #211F1F;
}