.ErrorText {
    color: red;
}

.InfoText {
    color: orange;
    padding-top: 50px;
    padding-bottom: 50px;
}

.JobStatus {
    color: orange;
    padding-top: 50px;
    padding-bottom: 50px;
}

.ThreeView {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}